import { FaCheck } from 'react-icons/fa';

const Amenities = () => {
  const amenities = [
    "Fully equipped kitchen with appliances and cooking utensils",
    "Shared living areas with comfortable furniture and TV",
    "High-speed internet and Wi-Fi access",
    "Laundry room with washing machine and dryer",
    "Outdoor space including a patio and spacious backyard",
    "Access to 12-step meetings and other recovery programs"
  ];

  const iconsStyle = { marginRight: "10px", fill:'#dbd7d2', fontSize:'1.5rem'};

  const displayAmenities = amenities.map(item => {
    return (
      <li style={{ display: "table-row" }}>
        <span style={{ display: "table-cell", verticalAlign: "middle" }}>
          <FaCheck style={iconsStyle} className='icons' />
        </span>
        <span style={{ display: "table-cell", verticalAlign: "middle" }}>
          {item}
        </span>
      </li>
    );
  });

  return (
    <ul style={{ listStyleType: 'none', paddingLeft: 0, marginTop:'40px', textAlign: 'left' }}>
      {displayAmenities}
    </ul>
  );
}

export default Amenities;